export class Anamnesis {
    level: number;
    currentlyPhysicalActivity: boolean;
    competitive: boolean;
    sport: string;
    numberTrainingSessionsWeek: number;
    hoursTrainingSessionsWeek: number;
    currentlyStrengthTraining: boolean;
    numberStrengthTrainingSessionsWeek: number;
    hoursStrengthTrainingSessionsWeek: number;
    strengthTrainingInThePast: boolean;
    timeSinceLastTraining: string;
    constantfollowUpSpreadsheet: boolean;
    recreationalAndSporadic: boolean;
    physicalActivityInThePast: boolean;
    trainingType: string;


}