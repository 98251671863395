export class Member {
    id: number;
    lastName: string;
    name: string;
    birthDate: string;
    phone: string;
    socialSecurity: string;
    address: string;
    emergencyPhone: string;
    emergencyContact: string;
    instagram: string;
    facebook: string;
    planId: number;
    email: string;
    password: string
  }
  